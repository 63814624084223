/* global $ */

$(document).ready(function () {
    "use strict";

    $("#menu-button").click(function () {
        $("#menu").toggleClass("navigation__menu--active");
        $("#menu-bg").addClass("navigation__menu-bg--active");
    });

    $("#menu-bg").click(function () {
        $("#menu").removeClass("navigation__menu--active");
        $("#menu-bg").removeClass("navigation__menu-bg--active");
    });
});